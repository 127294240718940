<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">参会人角色管理</h2>
        <div class="RightDiv">
          <div class="firDiv">
            <el-input v-model="roleName" clearable placeholder="角色名称" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
          </div>
          <div class="firDiv">
            <el-button type="primary" @click="addRole">新增角色</el-button>
          </div>
        </div>
        <el-table :data="tableData" border center>
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column prop="name" label="角色名称" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="创建日期" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template style="display: flex" slot-scope="scope">
              <el-button size="mini" type="danger" @click="deleteRole(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
        </el-pagination>

        <el-dialog title="新增角色" :visible.sync="revsuggdialogVisible" width="400px" :before-close="revsuggdialogVisibleClose">
          <el-form :model="AddDateModel" ref="AddDateModel" :rules="AddRoleRules" label-width="130px">
            <el-form-item label="角色名称：" prop="name">
              <el-input v-model="AddDateModel.name" placeholder="请填写角色名称"></el-input>
            </el-form-item>

            <el-form-item class="depar-dia btnCen">
              <el-button v-if="addRoleClick" type="primary" :loading="addRoleClickKing" @click="addRoleParmen">提交</el-button>
              <el-button v-else type="info" :loading="addRoleClickKing"></el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-main>
    </el-container>


  </el-container>
</template>

<script>
import _qs from "qs";
export default {
  data() {
    return {
      roleName:'',
      auditId:'',
      finReje:'',
      revsuggdialogVisible:false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,
      // 表格数据
      tableData: [],
      AddDateModel:{
        name:''
      },
      addRoleClick: true,
      addRoleClickKing: false,
      AddRoleRules:{
        name: [
          { required: true, message: "请填写角色名称", trigger: "blur" },
        ]
      },
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    searchName(){
      this.pageNum = 1;
      this.getRoleList();
    },
    revsuggdialogVisibleClose(){
      this.revsuggdialogVisible=false;
      this.AddDateModel.name = '';
    },
    addRoleParmen(){
      this.addRoleClick = false;
      this.addRoleClickKing = true;

      this.$refs.AddDateModel.validate(async (valid) => {
        if(valid){
          let data = _qs.stringify({
            name:this.AddDateModel.name
          });
          let { data: res } = await this.$axios.addAttendRole(data);
          // console.log(res)
          if (res.code == 401) {
            this.$router.push("/login");
          } else if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.revsuggdialogVisibleClose();
            this.getRoleList();
          }else{
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
          this.addRoleClick = true;
          this.addRoleClickKing = false;
        }
      })



    },
    change(e) {
      this.$forceUpdate(e);
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.getRoleList();
    },
    addRole(){
      this.revsuggdialogVisible=true
    },
    async deleteRole(row){
      const confirmResult = await this.$confirm("确认删除该角色", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      let data = _qs.stringify({
        id:row.id
      });
      let { data: res } = await this.$axios.deleteAttendRole(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getRoleList();
      }else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    async getRoleList() {
      let data = _qs.stringify({
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        name: this.roleName
      });
      let { data: res } = await this.$axios.attendRoleList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-pagination{
  text-align: center;
}
/deep/ .el-dialog__body {
  text-align: inherit;
  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv{
  text-align: center;
  margin-top: 20px;
  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}
.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;
    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis{
  width:380px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/deep/ .el-textarea__inner {
  width: 220px !important;
}

/deep/ .el-input__inner {
  width: 220px !important;
}

.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
}

.revSty{
  text-align: center;
  .el-button{
    width:150px;
    margin-top: 20px;
  }
}
</style>
